import React, { useState } from 'react';
import {
  Layout, Typography, Button,
  Form, Col, Divider, Row,
  Upload, ConfigProvider, InputNumber, Select
} from 'antd';
import ImgCrop from 'antd-img-crop';
import type { UploadFile } from 'antd/es/upload/interface';
import zhCN from 'antd/locale/zh_CN';
import type { Locale } from 'antd/es/locale';
import './App.css';
import { compressAccurately, compressAccuratelyConfig } from 'image-conversion';
import type { FormInstance } from 'antd/es/form';
import { Spin } from 'antd';
const { Text, Title } = Typography;
const { Header, Footer } = Layout;
const { Option } = Select;

const headerStyle: React.CSSProperties = {
  textAlign: 'left',
  color: '#fff',
  height: '8rem',
  // paddingInline: 50,
  lineHeight: '1rem',
  backgroundColor: '#7dbcea',
};

const footerStyle: React.CSSProperties = {
  textAlign: 'center',
  color: '#fff',
  backgroundColor: '#7dbcea',
};

const mystyle: React.CSSProperties = { backgroundColor: 'lightgrey', border: '1px dotted black' };

function App() {
  const [fileList, setFileList] = useState<UploadFile[]>([
    // {
    //   uid: '-1',
    //   name: 'image.png',
    //   status: 'done',
    //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    // },
  ]);

  const formRef = React.useRef<FormInstance>(null);
  const onFilesizeChange = (value: string | null) => {
    if (value != null) {
      setFilesizeStr(value);
    }
  }

  const onFilesizeUnitChange = (value: string | null) => {
    if (value != null) {
      setFilesizeUnit(value);
    }
  }
  // const onAccuracyChange = (value: number | null) => {
  //   if (value != null) {
  //     setAccuracy(value);
  //   }
  // }
  const onGenerate = () => {
    draw(originImgFile as File);
  }
  const onDownload = () => {
    const blob = dataURLtoFile(imgSrc, "");
    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.setAttribute(
      'download',
      'new_' + imgFilename,
    );
    // Append to html link element page
    document.body.appendChild(link);
    // Start download
    link.click();
    // Clean up and remove the link
    document.body.removeChild(link);
  }

  const [filesizeStr, setFilesizeStr] = useState('800');
  const [filesizeOrigin, setFilesizeOrigin] = useState(0);
  const [filesizeUnit, setFilesizeUnit] = useState('KB');
  // const [accuracy, setAccuracy] = useState(80);
  const [imgFilename, setImgFilename] = useState<string>("")
  const [imgSrc, setImgSrc] = useState<string>("")
  const [originImgFile, setOriginImgFile] = useState<File>()
  const [loading, setLoading] = useState(false);
  const loadingDelay = 400;

  //BeforeUploadValueType
  var onImgUploadOk = async (obj: any) => {
    console.log('onImgUploadOk', obj)
    let file = obj as File;
    setFilesizeOrigin(Math.floor(file.size / 1000));
    setOriginImgFile(file);
    setImgFilename(file.name as string);
    console.log(imgFilename)
    draw(file)
  };

  async function wait(fun1: any, fun2: any, d: number) {
    fun1();
    await new Promise((resolve, reject) => {
      setTimeout(() => resolve(fun2()), d)
    });
  }

  const showLodding = (display: boolean) => {
    if (display) {
      wait(() => { setLoading(true) }, () => { setLoading(false) }, loadingDelay);
    } else {
      setLoading(false);
    }
  };

  var draw = async (file: Blob) => {
    showLodding(true);
    let fsize;
    if (filesizeStr !== "") {
      fsize = Number(filesizeStr)
      if (filesizeUnit === "MB") {
        fsize = fsize * 1024;
      }
    } /*else if (accuracy > 0) {
      fsize = filesizeOrigin * accuracy;
    }*/
    console.log("draw", fsize)

    let compressOpts = { size: fsize, /*accuracy: accuracy, width: 500, height: 400,*/ }
    let compressFile = await compress(file, compressOpts)
    // type,
    // scale,
    // orientation
    const reader = new FileReader();
    reader.readAsDataURL(await compressFile);
    reader.onload = () => {
      setImgSrc(reader.result as string);
      console.log("imgLen", imgSrc.length, "imgSrc", imgSrc)
    };
    showLodding(false);
  }

  const dataURLtoFile = (dataurl: string, filename: string) => {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const getfilesize = (size: number) => {
    if (!size) return "";
    var num = 1024.00;
    if (size < num)
      return size + " B";
    if (size < Math.pow(num, 2))
      return (size / num).toFixed(1) + " KB";
    if (size < Math.pow(num, 3))
      return (size / Math.pow(num, 2)).toFixed(1) + " MB";
    if (size < Math.pow(num, 4))
      return (size / Math.pow(num, 3)).toFixed(1) + " G";
    return (size / Math.pow(num, 4)).toFixed(1) + " T";
  }

  // const b64toBlob = (b64Data: string, contentType = 'image/jpeg', sliceSize = 512) => {
  //   const byteCharacters = atou(b64Data);
  //   // const byteCharacters = Buffer.from(b64Data, 'base64').toString('base64');
  //   const byteArrays = [];
  //   for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
  //     const slice = byteCharacters.slice(offset, offset + sliceSize);

  //     const byteNumbers = new Array(slice.length);
  //     for (let i = 0; i < slice.length; i++) {
  //       byteNumbers[i] = slice.charCodeAt(i);
  //     }

  //     const byteArray = new Uint8Array(byteNumbers);
  //     byteArrays.push(byteArray);
  //   }

  //   const blob = new Blob(byteArrays, { type: contentType });
  //   return blob;
  // }

  //   const blob = b64toBlob(b64Data, "image/jpeg");
  // const blobUrl = URL.createObjectURL(blob);


  const [locale] = useState<Locale>(zhCN);
  // UploadRequestOption
  const customRequest = (opts: any) => {
    const { onSuccess, onError, file, onProgress } = opts;

    const fmData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
      onUploadProgress: () => { }
    };
    // fmData.append("image", file);
    try {
      // 调用实例的成功方法通知组件该文件上传成功
      onSuccess("Ok");
    } catch (err) {
      // 调用实例的失败方法通知组件该文件上传失败
      console.log("Eroor: ", err);
      onError({ err });
    }
  };

  async function compress(file: Blob, opts: compressAccuratelyConfig) {
    const compressFile = compressAccurately(file,
      opts,
      // size,
      // accuracy,
      // type,
      // width,
      // height,
      // scale,
      // orientation
    );
    return compressFile
  }

  return (
    <ConfigProvider locale={locale} >
      <div className="App" style={{ overflowX: 'hidden' }}>
        <Layout>
          <Header style={headerStyle}>
            <Title level={2}>图片压缩工具</Title>
            <Text>可裁剪、旋转、压缩图片到指定文件大小</Text>
          </Header>
          <Divider></Divider>
          <Layout>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="gutter-row" offset={1} span={8} style={mystyle}>
                <ImgCrop rotationSlider showReset showGrid aspectSlider aspect={4 / 3}
                  modalTitle="编辑图片" resetText="重置"
                  onModalOk={onImgUploadOk}>
                  <Upload
                    // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    // 覆盖默认的上传行为，定义自己的上传实现
                    customRequest={customRequest}
                    accept='.jpg,.jpeg,.png'
                    listType="text"
                    fileList={fileList}
                    // onChange={onChange}
                    // onPreview={onPreview}
                    maxCount={1}
                    showUploadList={{
                      showRemoveIcon: false,
                      showPreviewIcon: false,
                    }}
                  >
                    <Button>{fileList.length === 0 ? '上传图片' : '重选图片'}</Button>
                    <span>原图大小: {filesizeOrigin} KB</span>
                    <p>filesize:{filesizeStr}</p>
                    {/* <p>accuracy:{accuracy}</p> */}
                  </Upload>
                </ImgCrop>
                <Form
                  ref={formRef}
                  name="basic"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  style={{ maxWidth: 600 }}
                  initialValues={{ remember: true }}
                  // onFinish={onFinish}
                  // onFinishFailed={onFinishFailed}
                  autoComplete="off">
                  <Form.Item
                    label="图片大小"
                    name="filesize"
                    rules={[{ required: true, message: '必填' }]}
                    initialValue='800'>
                    <InputNumber precision={1} addonAfter={
                      <Select defaultValue={"KB"} style={{ width: 60 }} onChange={onFilesizeChange}>
                        <Option value="KB">KB</Option>
                        <Option value="MB">MB</Option>
                      </Select>} min='1' onChange={onFilesizeChange} />
                  </Form.Item>
                  {/* <Form.Item
                    label="清晰度"
                    name="accuracy" > */}
                  {/* <InputNumber style={{ float: 'left' }} max={0.99} min={0.8} defaultValue={0.8} */}
                  {/* step={0.01} placeholder="范围0.8~0.99" /> */}
                  {/* <Slider min={0.3} max={1} defaultValue={0.8} onAfterChange={onAccuracyChange} step={0.01}
                      tooltip={{ open: true, placement: 'bottom' }} */}
                  {/* marks={{
                        0.3: {
                          style: { color: 'grey', },
                          label: <span>低</span>,
                        },
                        1: {
                          style: { color: 'grey', },
                          label: <span>高</span>,
                        },
                      }} /> */}
                  {/* </Form.Item> */}
                  <Form.Item >
                    <Button onClick={onGenerate} type="primary">生成</Button>
                  </Form.Item>
                </Form>
              </Col>
              <Col className="gutter-row" offset={0} span={14} style={mystyle}>
                <Button onClick={onDownload} type="primary">下载</Button> <span>压缩图片大小: {getfilesize(imgSrc.length * 0.75)}</span>
                <Spin delay={loadingDelay} spinning={loading}></Spin>
                <img width={"100%"} src={imgSrc} alt="picture" />
              </Col>
            </Row>

            {/* <Content style={siderStyle}>
          </Content>
          <Content style={contentStyle}>Content</Content> */}
          </Layout>
          <Footer style={footerStyle}>&copy;fc4soda</Footer>
        </Layout>
      </div >
    </ConfigProvider >
  );
}

export default App;
